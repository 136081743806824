import { addons } from '@storybook/manager-api'

const ADDON_ID = 'storybook/footer'

addons.register(ADDON_ID, () => {
  console.log('Register footer add-on')

  const container = document.querySelector('#root > div') as HTMLDivElement
  container.style.height = 'calc(100vh - 20px)'

  const footer = document.createElement('div')
  footer.style.position = 'fixed'
  footer.style.bottom = '0px'
  footer.style.width = '100%'
  footer.style.height = '20px'
  footer.style.fontSize = '12px'
  footer.style.padding = '5px'
  footer.innerHTML = `&copy; ${new Date().getFullYear()} - State Street Corporation | Information Classification: General`
  document.body.appendChild(footer)
})
